// Login
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";

// Categories
export const CATEGORY_LOAD_START = "CATEGORY_LOAD_START";
export const CATEGORY_LOAD_FAILED = "CATEGORY_LOAD_FAILED";
export const CATEGORY_SUCCESSFULL = "CATEGORY_SUCCESSFULL";

// Quiz
export const QUESTION_LOAD_START = "QUESTION_LOAD_START";
export const QUESTION_LOAD_ERROR = "QUESTION_LOAD_ERROR";
export const SET_QUESTION = "SET_QUESTION";
